// @flow

import React from 'react'

import styles from './StandardContent.module.scss'

export default function StandardContent({ children }) {
  return (
    <div
      className={styles.container}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}
