// @flow
import React from 'react'
import { graphql } from 'gatsby'

import { withIntl } from 'src/intl'

import {
  Layout,
  Footer,
  NavbarLegal,
  BackgroundImageShort,
  PageRow,
  Heading,
} from 'src/common/components'
import HeadTags from 'src/components/HeadTags'
import StandardContent from 'src/components/ContentRow/StandardContent'

type PageContext = {
  locale: string,
  pagePath: string,
}

type Props = {
  data: Object,
  pageContext: PageContext,
}

const LegalPage = ({ data, pageContext }: Props) => {
  const { html } = data.markdownRemark
  const { footer } = data.footer.frontmatter
  const { meta, intro } = data.markdownRemark.frontmatter

  return (
    <>
      <HeadTags pageContext={pageContext} {...meta} />
      <Layout>
        {{
          navbar: <NavbarLegal />,
          background: <BackgroundImageShort />,
          footer: <Footer content={footer} />,
          body: (
            <PageRow firstRow>
              <Heading text={intro.heading} />
              <StandardContent>{html}</StandardContent>
            </PageRow>
          ),
        }}
      </Layout>
    </>
  )
}

export default withIntl(LegalPage)

export const LegalPageQuery = graphql`
  query LegalQuery($id: String!, $locale: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pageType
        ...MetaFragment
        intro {
          heading
          description
        }
      }
    }
    ...FooterFragment
  }
`
